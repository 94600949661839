<template>
  <div class="container">
    <el-form>
      <el-form-item label="券包名称">
        <el-input v-model="form.name" placeholder="券包名称"></el-input>
      </el-form-item>
      <el-form-item label="券包价值">
        <el-input v-model="form.cash" placeholder="券包价值"></el-input>元
      </el-form-item>
      <el-form-item label="券包说明">
        <el-input
          type="textarea"
          placeholder="券包说明"
          v-model="form.descr"
          maxlength="250"
          show-word-limit
        >
        </el-input>
      </el-form-item>
    </el-form>

    <div class="m-title">发放的优惠券</div>

    <div class="c-list">
      <div
        class="cou-item flex jc-b al-c"
        v-for="(c, i) in changeCoupons"
        :key="i"
      >
        <div class="del" @click="delCoupon(c)">
          <i class="el-icon-delete"></i>
        </div>

        <div class="c-cash">
          <p v-if="c.coupon.way == 1">{{ c.coupon.cash }}元</p>
          <p v-if="c.coupon.way == 2">
            {{ (c.coupon.discount * 10).toFixed(1) }}折
          </p>
          <p class="c-name">{{ c.coupon.name }}</p>
          <p class="c-desc">{{ c.coupon.descr }}</p>
        </div>
        <div class="num">x{{ c.num }}</div>
      </div>
    </div>

    <div class="flex al-c">
      <div class="s-t">券名称</div>
      <el-input v-model="c_name" placeholder="名称"></el-input>
      <div class="s-t">券id</div>
      <el-input v-model="c_id" placeholder="id"></el-input>
      <el-button type="primary" @click="getCouponList(1)">搜索</el-button>
    </div>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getCouponList"
    >
      <template #way="{ row }">
        <div>
          <p v-if="row.way == 1">面值</p>
          <p v-if="row.way == 2">折扣</p>
        </div>
      </template>
      <template #cash="{ row }">
        <div>
          <p v-if="row.way == 1">{{ row.cash }}元</p>
          <p v-if="row.way == 2">{{ (row.discount * 10).toFixed(1) }}折</p>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="openChange(row)"
            >选择</el-button
          >
        </div>
      </template>
    </auto-table>
    <div class="m-title">发放的会员卡</div>
    <div class="c-list">
      <div
        class="cou-item flex jc-b al-c"
        v-for="(c, i) in cards"
        :key="i"
      >
        <div class="del" @click="cards = []">
          <i class="el-icon-delete"></i>
        </div>
        
        <div class="c-cash">
          <p class="">{{ c.price }}元</p>
          <p class="c-name">{{ c.name }}</p>
        </div>
      </div>
    </div>
    <div>
      <el-select
        v-model="change_mamber"
        @change="changeCard"
        placeholder="请选择"
      >
        <el-option
          v-for="item in memberList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>

    <div class="foot">
      <el-button @click="back">取 消</el-button>
      <el-button
        v-if="form.id"
        type="success"
        @click="updatePackage"
        :loading="loading"
        >更 新 信 息</el-button
      >
      <el-button v-else type="success" :loading="loading" @click="insertPackage"
        >新 增 券 包</el-button
      >
    </div>

    <el-dialog title="发放数量" :visible.sync="dialogVisible" width="30%">
      <el-input v-model="num" type="Number"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="changeCoupon">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      num: 1,
      curCoupon: {},
      form: {},
      DataList: [],
      Option: [
        { name: "名称", value: "name" },
        { name: "类型", value: "way", type: "custom" },
        { name: "券值", value: "cash", type: "custom" },
        { name: "说明", value: "descr" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      c_name: "",
      c_id: "",
      changeCoupons: [],
      id: "",
      memberList: [],
      cards:[],
      change_mamber: "",
    };
  },

  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getInfo();
      this.getCoupons();
     
    }
     this.getMemberList();
  },

  methods: {
    changeCard(v) {
      this.memberList.forEach(item=>{
        if(item.id == v){
             this.cards = [item]
        }
      })
     
    },
    getMemberList() {
      this.$get("user/member/card/queryListPage", {
        type: 1,
        status: 1,
        pageSize: 30,
      }).then((res) => {
        this.memberList = res.data.data;
      });
    },
    getInfo() {
      this.$axios({
        url: "/user/couponPackage/findById",
        params: {
          id: this.id,
        },
      }).then((res) => {
        this.form = res.data.data;
      });
    },
    getCoupons() {
      this.$axios({
        url: "/user/cpRelative/query",
        params: {
          package_id: this.id,
        },
      }).then((res) => {
          res.data.data.forEach(item=>{
            if(item.coupon_id > 0){
              this.changeCoupons.push(item)
            }else if(item.card_id > 0){
              this.cards.push(item.card)
            }
          })


        
      });
    },
    updatePackage() {
      if (!this.form.name) {
        this.$message("请输入名称");
        return;
      }
      if (!this.form.cash) {
        this.$message("请输入券价值");
        return;
      }

      this.loading = true;
      let data = { ...this.form };

       let cids = this.changeCoupons.map((t) => {
        return { num: t.num, coupon_id: t.coupon.id,card_id:0 };
      });

        let cardids = this.cards.map((t) => {
        return { num:1, coupon_id: 0,card_id:t.id };
      });

      data.coupons = [...cids,...cardids]


    

      this.$axios({
        url: "/user/couponPackage/update",
        method: "post",
        data: data,
      })
        .then((res) => {
          this.$notify({
            message: "修改成功",
            duration: 2000,
            type: "success",
          });
          this.loading = false;
          this.$router.push("couponPackage");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    insertPackage() {
      if (!this.form.name) {
        this.$message("请输入名称");
        return;
      }
      if (!this.form.cash) {
        this.$message("请输入券价值");
        return;
      }

      this.loading = true;
      let data = { ...this.form };

      let cids = this.changeCoupons.map((t) => {
        return { num: t.num, coupon_id: t.coupon.id,card_id:0 };
      });

        let cardids = this.cards.map((t) => {
        return { num:1, coupon_id: 0,card_id:t.id };
      });

      data.coupons = [...cids,...cardids]
      this.$axios({
        url: "/user/couponPackage/insert",
        method: "post",
        data: data,
      })
        .then((res) => {
          this.$notify({
            message: "新增成功",
            duration: 2000,
            type: "success",
          });
          this.loading = false;
          this.$router.push("couponPackage");
        })
        .catch(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
    delCoupon(row) {
      this.$confirm("确认将优惠券移除券包吗").then(() => {
        let ids = this.changeCoupons.map((t) => t.coupon.id);
        let i = ids.indexOf(row.coupon.id);
        if (i != -1) {
          this.changeCoupons.splice(i, 1);
        }
      });
    },
    openChange(row) {
      this.num = 1;
      this.curCoupon = row;
      let ids = this.changeCoupons.map((t) => t.coupon.id);
      let i = ids.indexOf(this.curCoupon.id);
      if (i == -1) {
        this.dialogVisible = true;
      } else {
        this.$message("已添加");
      }
    },
    changeCoupon() {
      this.changeCoupons.push({ num: this.num, coupon: this.curCoupon });
      this.dialogVisible = false;
    },
    //获取列表
    getCouponList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/commoditycoupon/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: 2,
          id: this.c_id || null,
          name: this.c_name || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.s-t {
  padding: 0 10px;
}

.m-title {
  font-size: 16px;
  margin: 20px 0;
}
.c-list {
  display: flex;
  flex-wrap: wrap;
}
.cou-item {
  flex-shrink: 0;
  width: 170px;
  padding: 10px;
  border-radius: 4px;
  background-color: rgb(241, 184, 77);
  color: #fff;
  margin: 0 10px 10px 0;
  position: relative;
  .del {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 15px;
    cursor: pointer;
  }
}
.num {
  padding-left: 7px;
  font-size: 17px;
}
.c-cash {
  font-size: 20px;
}
.c-name {
  font-size: 14px;
}
.c-desc {
  font-size: 12px;
  margin-top: 3px;
}
.foot {
  margin-top: 20px;
}
</style>